// extracted by mini-css-extract-plugin
export var bottomBodyHeading = "TabsSlider-module--bottomBodyHeading--62d63";
export var bottomBodyParagraph = "TabsSlider-module--bottomBodyParagraph--f2f0b";
export var bottomBodyRoot = "TabsSlider-module--bottomBodyRoot--22d5c";
export var button = "TabsSlider-module--button--2d745";
export var buttonListItem = "TabsSlider-module--buttonListItem--1205e";
export var buttonsWrapper = "TabsSlider-module--buttonsWrapper--1c0d0";
export var content = "TabsSlider-module--content--0b79c";
export var headingH2 = "TabsSlider-module--headingH2--76bf9";
export var headingH3 = "TabsSlider-module--headingH3--4f0ed";
export var icon = "TabsSlider-module--icon--0dc17";
export var image = "TabsSlider-module--image--0650b";
export var link = "TabsSlider-module--link--3e899";
export var opacityEnter = "TabsSlider-module--opacityEnter--c4ca3";
export var opacityExit = "TabsSlider-module--opacityExit--23988";
export var rollDown = "TabsSlider-module--rollDown--46ff7";
export var rollUp = "TabsSlider-module--rollUp--92952";
export var root = "TabsSlider-module--root--6c31b";
export var slideInDown = "TabsSlider-module--slideInDown--e9264";
export var slideOutUp = "TabsSlider-module--slideOutUp--0f691";
export var slideWrapper = "TabsSlider-module--slideWrapper--dbfd3";
export var splashEnter = "TabsSlider-module--splashEnter--a8b30";
export var splashExit = "TabsSlider-module--splashExit--021cc";
export var wrapper = "TabsSlider-module--wrapper--4edde";