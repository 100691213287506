import React, { useState } from 'react';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';
import Image from '@/components/elements/Image';
import SectionRichText from '../SectionRichText';
import type { TabsSliderTypes, TabsSlideTypes } from './TabsSlider.types';
import * as styles from './TabsSlider.module.scss';

const bottomBodyStyles = {
  root: styles.bottomBodyRoot,
  headingH3: styles.bottomBodyHeading,
  headingH4: styles.bottomBodyHeading,
  paragraph: styles.bottomBodyParagraph,
};

function ControlButtons({
  group,
  currentSlideIndex,
  changeHandler,
}: {
  group: TabsSlideTypes[];
  currentSlideIndex: number;
  changeHandler: (value: number) => void;
}) {
  return (
    <ul className={styles.buttonsWrapper}>
      {group.map(({ _uid, icon }, index) => (
        <li key={_uid} className={styles.buttonListItem} data-active={String(index === currentSlideIndex)}>
          <button onClick={() => changeHandler(index)} className={styles.button}>
            <Image data={icon} fixed options={{ width: 120 }} className={styles.icon} />
          </button>
        </li>
      ))}
    </ul>
  );
}

function DisplayedSlide({ body, bottom_body, image, handlers }: TabsSlideTypes & { handlers: SwipeableHandlers }) {
  return (
    <div {...handlers} className={styles.slideWrapper}>
      {body && (
        <div className={styles.content}>
          <SectionRichText body={body} className={styles} arrowLinks />
          {bottom_body && <SectionRichText body={bottom_body} className={bottomBodyStyles} />}
        </div>
      )}
      {image && <Image data={image} fluid className={styles.image} />}
    </div>
  );
}

export default function TabsSlider({ slides }: TabsSliderTypes) {
  const [currentlySelectedIndex, changeSelectedIndex] = useState<number>(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentlySelectedIndex === slides.length - 1) return changeSelectedIndex(0);
      return changeSelectedIndex(currentlySelectedIndex + 1);
    },
    onSwipedRight: () => {
      if (currentlySelectedIndex === 0) return changeSelectedIndex(slides.length - 1);
      return changeSelectedIndex(currentlySelectedIndex - 1);
    },
  });

  function setSelectedIndex(index: number) {
    return changeSelectedIndex(index);
  }

  if (!slides) return null;
  return (
    <div className={styles.wrapper}>
      <ControlButtons group={slides} currentSlideIndex={currentlySelectedIndex} changeHandler={setSelectedIndex} />
      <DisplayedSlide {...slides[currentlySelectedIndex]} handlers={handlers} />
    </div>
  );
}
